import React from "react";
import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CreatableRateCard } from "../../api/rateCard";
import RateCardDetailsToolbar from "../../components/rateCards/RateCardDetailsToolbar";
import RateCardCreate from "../../components/rateCards/RateCardCreate";
import { useLoadingInfoState } from "../../components/Loading/useLoadableState";
import { performAsyncAction } from "../../components/Loading/useAsync";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import useApiClient from "../../api/useApiClient";

const RateCardDetails = () => {
  const navigate = useNavigate();
  const [creatableRateCard, setCreatableRateCard] =
    React.useState<CreatableRateCard>({ external_id: "", name: "" });

  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  return (
    <>
      <Helmet>
        <title>New Rate Card</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <RateCardDetailsToolbar
            name="New Rate Card"
            canDelete={false}
            editing={creatableRateCard !== undefined}
            onSave={() => {
              if (creatableRateCard) {
                performAsyncAction(
                  () => client.rate.card.create(creatableRateCard),
                  setLoadingInfo,
                  enqueueSnackbar,
                  () => navigate("/app/rateCards")
                );
              }
            }}
            onCancel={() => navigate("/app/rateCards")}
          />
          <Box sx={{ pt: 3 }}>
            <RateCardCreate
              value={creatableRateCard}
              onChange={(x) => setCreatableRateCard(x)}
            />
          </Box>
        </Container>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default RateCardDetails;
