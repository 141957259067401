import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { RateVersionDetail, S3Version } from "../../api/publishing";
import { Download } from "@mui/icons-material";
import formatDate from "../../utils/formatDate";
import { Link } from "react-router-dom";
import { RateTableDataType } from "../../api/rateTable";

interface RateSetRowProps {
  rowSpan: number;
  externalId: string;
  effectiveDate: string;
  expirationDate: string;
  rateTableExternalId: string;
}

const RateSetRow = (props: RateSetRowProps) => (
  <>
    <TableCell rowSpan={props.rowSpan}>
      <Link to={`/app/rateSet/${props.externalId}`}>{props.externalId}</Link>
      <br />
      {formatDate(props.effectiveDate)} - {formatDate(props.expirationDate)}
    </TableCell>
    <TableCell>
      <Link to={`/app/rateTable/${props.rateTableExternalId}`}>{props.rateTableExternalId}</Link>
    </TableCell>
  </>
);

interface DataDetailsProps {
  versionDetail: RateVersionDetail;
  onDownload: (externalId: string, type: RateTableDataType, version: string) => void;
}

const getDataTypeAndVersion = (versions: S3Version[]) =>
  ["rate", "variable", "discount"].map((dataType) => ({
    dataType,
    version: versions.find((v) => v.type === dataType),
  }));

const DataDetails = (props: DataDetailsProps) => (
  <>
    {getDataTypeAndVersion(props.versionDetail?.versions).map((value) => (
      <TableCell align="center" key={`${props.versionDetail.external_id}-${value.dataType}`}>
        {value.version && (
          <>
            <Typography
              color="textSecondary"
              textTransform={"uppercase"}
              variant="caption"
              title={`${value.dataType}s`}
            >
              {value.dataType}S
            </Typography>
            <Tooltip title={`Download ${value.dataType}s`}>
              <IconButton
                onClick={() =>
                  props.onDownload(
                    props.versionDetail.external_id,
                    `${value.dataType}s` as RateTableDataType,
                    value.version?.s3Version || ""
                  )
                }
                aria-label={`Download ${value.dataType}s`}
                color="success"
                size="small"
              >
                <Download />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCell>
    ))}
  </>
);
interface RateCardVersionRatesResultsProps {
  rateCardVersionRates: RateVersionDetail[];
  onDownloadData: (externalId: string, type: RateTableDataType, version: string) => Promise<Blob>;
}

const RateCardVersionRatesResults = ({ rateCardVersionRates, onDownloadData }: RateCardVersionRatesResultsProps) => {
  const groupedByRateSet = rateCardVersionRates.reduce<Record<string, RateVersionDetail[]>>((result, current) => {
    (result[current.rate_set_external_id] = result[current.rate_set_external_id] || []).push(current);
    return result;
  }, {});

  const handleDownload = (externalId: string, type: RateTableDataType, version: string) => {
    void onDownloadData(externalId, type, version).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${externalId}_${type}_${version}.json`;
      a.click();
    });
  };

  return (
    <>
      <Card>
        <CardContent>
          <Table size="small" width="100%">
            <TableHead>
              <TableRow>
                <TableCell>Rate Sets</TableCell>
                <TableCell>Rate Tables</TableCell>
                <TableCell align="center" colSpan={3}>
                  Data Download
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedByRateSet).map(([key, rateSets]) => (
                <>
                  <TableRow hover key={`${key}-${rateSets[0].external_id}`}>
                    <RateSetRow
                      rowSpan={rateSets.length}
                      externalId={key}
                      effectiveDate={rateSets[0].effective_date_utc}
                      expirationDate={rateSets[0].expiration_date_utc}
                      rateTableExternalId={rateSets[0].external_id}
                    />
                    <DataDetails versionDetail={rateSets[0]} onDownload={handleDownload} />
                  </TableRow>
                  {rateSets.slice(1)?.map((rateTable: any) => (
                    <TableRow hover key={`${key}-${rateTable.external_id}`}>
                      <TableCell>
                        <Link to={`/app/rateTable/${rateTable.external_id}`}>{rateTable.external_id}</Link>
                      </TableCell>
                      <DataDetails versionDetail={rateTable} onDownload={handleDownload} />
                    </TableRow>
                  ))}
                </>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default RateCardVersionRatesResults;
