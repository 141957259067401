import joinUrlParts from "../utils/joinUrlParts";
import { ApiFetch } from "./client";
import { throwOnError } from "./throwOnError";

export interface RatingLogicVersion {
  size: number;
  version: string;
  latest: boolean;
  lastModified: string;
}

export interface RatingLogicApi {
  upload: (code: string, preFormatted?: boolean) => Promise<void>;
  download: (version?: string) => Promise<Blob>;
  get: () => Promise<string | undefined>;
  versions: (page: number, itemsPerPage: number) => Promise<RatingLogicVersion[]>;
  remove: () => Promise<void>;
}

const encode = (input: string): string => (input ? btoa(input) : "");
const decode = (input: string): string => (input ? atob(input) : "");

const ratingLogic = (client: ApiFetch, extraPath: string[] = []): RatingLogicApi => {
  const upload = async (code: string, preFormatted: boolean = false): Promise<void> => {
    const results = await client(joinUrlParts(...extraPath, "code"), {
      method: "post",
      body: !preFormatted ? JSON.stringify({ code: encode(code) }) : code,
    });

    await throwOnError(results);
  };

  const get = async (): Promise<string | undefined> => {
    const results = await client(joinUrlParts(...extraPath, "code"), {
      method: "GET",
    });

    if (results.status === 404) {
      return undefined;
    }

    await throwOnError(results);

    return decode((await results.json())?.code);
  };

  const download = async (version?: string): Promise<Blob> => {
    const queryParam = !version ? "" : `?version=${version}`;
    const results = await client(joinUrlParts(...extraPath, `code${queryParam}`));

    await throwOnError(results);

    return results.blob();
  };

  const versions = async (page: number = 1, itemsPerPage: number = 10): Promise<RatingLogicVersion[]> => {
    const results = await client(
      joinUrlParts(...extraPath, "code", `versions?page=${page}&itemsPerPage=${itemsPerPage}`),
      {
        method: "GET",
      }
    );
    return (await results.json()).versions;
  };

  const remove = async (): Promise<void> => {
    const results = await client(joinUrlParts(...extraPath, "code"), { method: "delete" });

    await throwOnError(results);
  };

  return {
    upload,
    get,
    download,
    versions,
    remove,
  };
};

export default ratingLogic;
