import React from "react";
import { RatingLogicVersion } from "../../api/ratingLogic";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import LoadingWrapper from "../Loading/LoadingWrapper";
import LoadingTableRow from "../Loading/LoadingTableRow";
import formatDate from "../../utils/formatDate";
import { Loadable } from "../Loading/Loadable";
import { SaveAlt } from "@mui/icons-material";

interface CodeVersionsListProps {
  versions: Loadable<RatingLogicVersion[]>;
  /** Function to execute when a rate card entry is clicked */
  onClick: (rateCardId: string) => void;
}

const CodeVersionsList = ({ versions, onClick, ...rest }: React.PropsWithChildren<CodeVersionsListProps>) => {
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Latest</TableCell>
                <TableCell>Last Modified</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Size</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <LoadingWrapper loadingInfo={versions} loadingComponent={<LoadingTableRow colSpan={4} />}>
                {versions?.data?.map((version) => (
                  <TableRow hover key={version.version}>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {version.latest ? "Latest" : ""}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={version.lastModified}>
                        <span>{formatDate(version.lastModified)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{version.version}</TableCell>
                    <TableCell>{version.size}</TableCell>
                    <TableCell>
                      <IconButton color="inherit" size="large" onClick={() => onClick(version.version)}>
                        <SaveAlt />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </LoadingWrapper>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default CodeVersionsList;
