import { ApiFetch } from "./client";
import ratingLogic, { RatingLogicApi } from "./ratingLogic";
import { throwOnError } from "./throwOnError";

export interface CarrierDetails {
  /** Id of the carrier */
  id: string;
  /** Name */
  name: string;
  /** API Code */
  apiCodes: string[];
  /** Description of the carrier */
  description?: string;
}

export interface CarrierListResponse {
  /** List of available carriers */
  carriers: CarrierDetails[];
}

interface CarrierLogItem {
  level: string;
  message: string;
  rateCard: string;
  timestamp: string;
  transactionId: string;
}

/** Response for a carrier logs request*/
export interface CarrierLogsResponse {
  /** List of carrier log items */
  logs: CarrierLogItem[];
}

export interface CarrierApi {
  getAll: () => Promise<CarrierListResponse>;
  getLogs: () => Promise<CarrierLogsResponse>;
  downloadLogs: () => Promise<Blob>;
  ratingLogic: RatingLogicApi;
}

const carrier = (basicClient: ApiFetch, carrierClient: ApiFetch) => {
  const getAll = async (): Promise<CarrierListResponse> => {
    const results = await basicClient("carriers");

    await throwOnError(results);

    const carrierData = await results.json();

    return {
      ...carrierData,
      carriers: carrierData.carriers.map((carrier: any) => ({
        id: carrier.carrier_id,
        name: carrier.name,
        apiCodes: carrier.api_codes,
        description: carrier.description,
      })),
    };
  };

  const getLogs = async (): Promise<CarrierLogsResponse> => {
    const results = await carrierClient("logs");

    await throwOnError(results);

    return await results.json();
  };

  const downloadLogs = async (): Promise<Blob> => {
    const results = await carrierClient("logs");

    await throwOnError(results);

    return results.blob();
  };

  return { getAll, getLogs, downloadLogs, ratingLogic: ratingLogic(carrierClient) };
};

export default carrier;
