import joinUrlParts from "../utils/joinUrlParts";
import { ApiFetch } from "./client";
import { RateCardVersionDetailResponse, RateCardVersionListResponse } from "./publishing";
import { RateSetItem } from "./rateSet";
import ratingLogic, { RatingLogicApi } from "./ratingLogic";
import { throwOnError } from "./throwOnError";

export interface RateCardListResponse {
  rate_cards: RateCardItem[];
  total_count: number;
}

export interface RateCardItem {
  external_id: string;
  name: string;
  description?: string;
  /** The UTC date-time the rate card was created */
  created_date_utc?: string;
  /** The UTC date-time the rate card was last modified. */
  last_modified_date_utc?: string;
}

export type CreatableRateCard = { external_id: string } & EditableRateCard;

export interface EditableRateCard {
  name: string;
  description?: string;
}

export interface RateCardApi {
  getAll: (page: number, itemsPerPage: number) => Promise<RateCardListResponse>;
  get: (rateCardId: string) => Promise<RateCardItem>;
  create: (newValue: CreatableRateCard) => Promise<void>;
  update: (rateCardId: string, newValue: EditableRateCard) => Promise<void>;
  remove: (rateCardId: string) => Promise<void>;
  associateRateSets: (rateCardId: string, rateSetIds: string[]) => Promise<void>;
  disassociateRateSet: (rateCardId: string, rateSetId: string) => Promise<void>;
  getRateSets: (rateCardId: string) => Promise<RateSetItem[]>;
  getRateCardVersions: (rateCardId: string) => Promise<RateCardVersionListResponse>;
  getRateCardVersionDetail: (rateCardId: string, rateCardVersion: string) => Promise<RateCardVersionDetailResponse>;
  ratingLogic: (rateCardId: string) => RatingLogicApi;
}

const rateCard = (client: ApiFetch): RateCardApi => {
  const getAll = async (page = 0, itemsPerPage = 10): Promise<RateCardListResponse> => {
    const results = await client(`rate-cards?page=${page}&itemsPerPage=${itemsPerPage}`);

    await throwOnError(results);

    return await results.json();
  };

  const get = async (rateCardId: string): Promise<RateCardItem> => {
    const results = await client(joinUrlParts("rate-cards", rateCardId));

    await throwOnError(results);

    return await results.json();
  };

  const create = async (newValue: CreatableRateCard): Promise<void> => {
    const results = await client("rate-cards", {
      method: "POST",
      body: JSON.stringify(newValue),
    });

    await throwOnError(results);
  };

  const update = async (rateCardId: string, newValue: EditableRateCard): Promise<void> => {
    const results = await client(joinUrlParts("rate-cards", rateCardId), {
      method: "PUT",
      body: JSON.stringify(newValue),
    });

    await throwOnError(results);
  };

  const remove = async (rateCardId: string): Promise<void> => {
    const results = await client(joinUrlParts("rate-cards", rateCardId), {
      method: "DELETE",
    });

    await throwOnError(results);
  };

  const associateRateSets = async (rateCardId: string, rateSetIds: string[]): Promise<void> => {
    const results = await client(joinUrlParts("rate-cards", rateCardId, "rate-sets"), {
      method: "PATCH",
      body: JSON.stringify({ rate_sets: rateSetIds }),
    });

    await throwOnError(results);
  };

  const disassociateRateSet = async (rateCardId: string, rateSetId: string): Promise<void> => {
    const results = await client(joinUrlParts("rate-cards", rateCardId, "rate-sets", rateSetId), {
      method: "DELETE",
    });

    await throwOnError(results);
  };

  const getRateSets = async (rateCardId: string): Promise<RateSetItem[]> => {
    const results = await client(joinUrlParts("rate-cards", rateCardId, "rate-sets"));

    await throwOnError(results);

    return await results.json();
  };

  const getRateCardVersions = async (rateCardId: string): Promise<RateCardVersionListResponse> => {
    const results = await client(joinUrlParts("rate-cards", rateCardId, "publish"));

    await throwOnError(results);

    return await results.json();
  };

  const getRateCardVersionDetail = async (
    rateCardId: string,
    rateCardVersion: string,
  ): Promise<RateCardVersionDetailResponse> => {
    const results = await client(joinUrlParts("rate-cards", rateCardId, "publish", rateCardVersion));

    await throwOnError(results);

    return await results.json();
  };

  return {
    getAll,
    get,
    create,
    update,
    remove,
    associateRateSets,
    disassociateRateSet,
    getRateSets,
    getRateCardVersions,
    getRateCardVersionDetail,
    ratingLogic: (rateCardId: string) => ratingLogic(client, ["rate-cards", rateCardId]),
  };
};

export default rateCard;
