import React from "react";
import { Button, ButtonGroup, Card, CardContent, Typography } from "@mui/material";
import DialogBox from "../DialogBox";
import { useSnackbar } from "notistack";

interface CodeUploadProps {
  onUploadCodeFile: (data: string) => void;
  onDownloadData: () => Promise<void>;
}

const CodeUpload = ({ onUploadCodeFile, onDownloadData }: CodeUploadProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadData, setUploadData] = React.useState(false);
  const [uploadButtonDisabled, setUploadButtonDisabled] = React.useState(true);
  const fileInput = React.useRef<HTMLInputElement>(null);
  const closeModal = () => setUploadData(false);

  const handleFileChange = (event: { target: { files: FileList | null } }) => {
    const file = event.target.files && event.target.files[0];
    !file ? setUploadButtonDisabled(true) : setUploadButtonDisabled(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography color="textPrimary" variant="h6" sx={{ pb: 1 }}>
            Or Upload a Code File.
          </Typography>
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setUploadButtonDisabled(true);
                setUploadData(true);
              }}
            >
              Upload
            </Button>
            <Button sx={{ ml: 3 }} color="primary" variant="contained" onClick={() => onDownloadData()}>
              Download
            </Button>
          </ButtonGroup>
          <Button sx={{ ml: 3 }} color="primary" variant="contained" href="/sampleData/sample_code.json" download>
            Sample
          </Button>
        </CardContent>
      </Card>
      <DialogBox open={uploadData} onClose={closeModal} title="Upload file" description="Select the file to upload">
        <input type="file" ref={fileInput} onChange={handleFileChange} />
        <Button
          sx={{ m: 2 }}
          variant="contained"
          disabled={uploadButtonDisabled}
          onClick={() => {
            const file = fileInput.current?.files?.[0];
            if (file === undefined) {
              return;
            }

            file
              .text()
              .then((text) => onUploadCodeFile(text))
              .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
              .finally(closeModal);
          }}
        >
          Upload
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
      </DialogBox>
    </>
  );
};

export default CodeUpload;
