import { Box, Container, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Helmet } from "react-helmet-async";
import { ApiClient } from "../../api/client";
import useApiClient from "../../api/useApiClient";
import CarrierContext from "../../components/CarrierContext";
import LoadingFullScreen from "../../components/Loading/LoadingFullScreen";
import { performAsyncAction, useAsync } from "../../components/Loading/useAsync";
import { useLoadableState, useLoadingInfoState } from "../../components/Loading/useLoadableState";
import CodeUpload from "../../components/ratingLogic/CodeUpload";
import LogicEditor from "../../components/ratingLogic/LogicEditor";
import CodeVersionList from "../../components/ratingLogic/CodeVersionList";
import { RatingLogicVersion } from "../../api/ratingLogic";
import { downloadAs } from "../../utils/downloadAs";

const CodeUploadDetails = () => {
  const [loadingInfo, setLoadingInfo] = useLoadingInfoState();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();

  const [codeResult, setCodeResult] = useLoadableState<string | undefined>(undefined);
  const [versions, setVersions] = useLoadableState<RatingLogicVersion[]>([]);

  const { carrier } = React.useContext(CarrierContext);

  const onUploadCodeFile = (data: string) => client.carrier.ratingLogic.upload(data, true);
  const onUploadRawCode = (rawCode: string) => client.carrier.ratingLogic.upload(rawCode, false);
  const onDownloadData = (version?: string) =>
    client.carrier.ratingLogic
      .download(version)
      .then(downloadAs(`${carrier?.name?.replace(/ /g, "")}_code_${version || "latest"}.json`));

  const getCodeFromServer = React.useCallback((apiClient: ApiClient) => apiClient.carrier.ratingLogic.get(), []);
  const getVersions = React.useCallback((apiClient: ApiClient) => apiClient.carrier.ratingLogic.versions(0, 50), []);

  useAsync(getCodeFromServer, setCodeResult);
  useAsync(getVersions, setVersions);

  return (
    <>
      <Helmet>
        <title>Rating Logic</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Grid container>
          <Grid item xs={8}>
            <Container maxWidth={false} data-color-mode="dark">
              <Box>
                <Typography variant="h1">Carrier Rating Logic</Typography>
              </Box>
              <Box sx={{ pt: 2 }}>
                <LogicEditor
                  code={codeResult?.data || ""}
                  onUploadCode={(rawString) => {
                    performAsyncAction(
                      () => onUploadRawCode(rawString),
                      setLoadingInfo,
                      enqueueSnackbar,
                      () =>
                        client.carrier.ratingLogic.get().then((x) =>
                          setCodeResult({
                            state: "finished",
                            data: x,
                          })
                        )
                    );
                  }}
                />
              </Box>
            </Container>
          </Grid>
          <Grid item xs={4}>
            <Container maxWidth={false}>
              <Box>
                <Typography variant="h1">File Upload</Typography>
              </Box>
              <Box sx={{ pt: 2 }}>
                <CodeUpload
                  onDownloadData={onDownloadData}
                  onUploadCodeFile={(data) => {
                    performAsyncAction(
                      () => onUploadCodeFile(data),
                      setLoadingInfo,
                      enqueueSnackbar,
                      () =>
                        client.carrier.ratingLogic.get().then((x) =>
                          setCodeResult({
                            state: "finished",
                            data: x,
                          })
                        )
                    );
                  }}
                />
              </Box>
            </Container>

            <Container maxWidth={false}>
              <Box marginTop={5}>
                <Typography variant="h1">Versions</Typography>
              </Box>
              <Box sx={{ pt: 2 }}>
                <CodeVersionList versions={versions} onClick={onDownloadData} />
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <LoadingFullScreen loadingInfo={loadingInfo} />
    </>
  );
};

export default CodeUploadDetails;
